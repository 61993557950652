<template>
  <div class="row">
    <div class="col-md-4 h-100">
      <b-card no-body class="h-100">
        <div class="mx-auto">
          <b-card-header>
            <b-avatar
              size="70px"
              :src="require('@/assets/images/portrait/small/avatar-s-20.jpg')"
            />
          </b-card-header>
        </div>
        <div class="mx-auto d-flex">
          <h4 class="mr-1">KabayanConsulting.co.id</h4>
          <feather-icon
            icon="ExternalLinkIcon"
            size="20"
            class="clickable"
            style="margin-top: -15px"
            v-b-tooltip.hover.v-dark="'Kunjungi'"
          />
        </div>
        <div class="mx-auto d-flex">
          <feather-icon icon="MapPinIcon" class="mr-50" />
          <h6>Jl. Indonesia Raya No.6</h6>
        </div>

        <div class="row mt-3">
          <div class="col-md-6">
            <div class="d-flex float-right">
              <b-media-aside>
                <b-avatar rounded size="40" variant="light-primary">
                  <feather-icon
                    icon="UserIcon"
                    size="24"
                    class="text-primary"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <div class="d-block"><h5>167 K</h5></div>
                <div class="d-block">Follower</div>
              </b-media-body>
            </div>
          </div>
          <div class="col-md-6">
            <div class="d-block mx-auto">
              <div class="d-flex">
                <b-media-aside>
                  <b-avatar rounded size="40" variant="light-primary">
                    <i class="fa-solid fa-comments text-primary fa-xl"></i>
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <div class="d-block"><h5>700 K</h5></div>
                  <div class="d-block">Mention</div>
                </b-media-body>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5" style="margin-bottom: 6.4rem">
          <b-card no-body>
            <b-card-header class="mx-auto mt-5">
              <b-card-title>Sentimen Postingan</b-card-title>
            </b-card-header>
            <div>
              <pieChart :data="pie_chart_data" />
              <div class="row mt-2">
                <div class="col-4">
                  <div class="float-right">
                    <center>
                      <h6>Positif</h6>
                      <i class="bi bi-emoji-smile fa-2x text-success"></i>
                      <h4>200</h4>
                    </center>
                  </div>
                </div>
                <div class="col-4">
                  <center>
                    <h6>Netral</h6>
                    <i class="bi bi-emoji-neutral fa-2x text-primary"></i>
                    <h4>200</h4>
                  </center>
                </div>
                <div class="col-4">
                  <div class="float-left">
                    <center>
                      <h6>Negatif</h6>
                      <i class="bi bi-emoji-frown fa-2x text-danger"></i>
                      <h4>200</h4>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </div>
      </b-card>
    </div>
    <div class="col-md-8 h-100">
      <b-card no-body class="h-100">
        <b-card-header class="mb-2">
          <b-card-title>Tren Postingan</b-card-title>
        </b-card-header>
        <b-card-body>
          <lineChart :data="chart_data" />
        </b-card-body>
      </b-card>
      <b-card no-body class="h-100">
        <b-card-header class="mb-2">
          <b-card-title>Postingan Terbaru</b-card-title>
        </b-card-header>
        <b-card-body>
          <div class="scroll-component px-2 pt-1">
            <div
              v-for="items in item_pages"
              :key="items.id"
              class="shadow rounded"
            >
              <!-- NEWS CARD COMPONENT -->
              <newsMedsosCard />
            </div>
            <!-- PAGINATION -->
            <div class="row">
              <div class="col-md-12 float-right">
                <b-pagination
                  v-model="current_page"
                  :total-rows="items.length"
                  :per-page="per_page"
                  align="right"
                  size="sm"
                  @change="updatePage"
                />
              </div>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BAvatar,
  VBTooltip,
  BPagination,
  BMedia,
  BMediaAside,
  BMediaBody,
} from "bootstrap-vue";
import lineChart from "./lineChart.vue";
import newsMedsosCard from "./newsMedsosCard.vue";
import pieChart from "./pieChart.vue";
import { $themeColors } from "@themeConfig";

export default {
  name: "detailAkun",
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BAvatar,
    BPagination,
    BMedia,
    BMediaAside,
    BMediaBody,
    lineChart,
    newsMedsosCard,
    pieChart,
  },

  data: () => ({
    pie_chart_data: {
      colors: [$themeColors.success, $themeColors.primary, $themeColors.danger],
      legend: false,
      labels: ["Positif", "Netral", "Negatif"],
      series: [5, 6, 7],
    },
    chart_data: {
      colors: ["#28C76F", "#82868B", "#E83E8C"],
      categories: ["17", "18", "19", "20", "21", "22", "23"],
      series: [
        {
          name: "Media Online",
          data: [45000, 47000, 47800, 49500, 45500, 48000, 46500, 48600],
        },
        {
          name: "Media Cetak",
          data: [45000, 46000, 45500, 44600, 44500, 46500, 45000, 47000],
        },
        {
          name: "Media Elektronik",
          data: [45000, 42000, 41500, 43600, 44500, 46500, 45000, 47000],
        },
      ],
    },
    current_page: 1,
    per_page: 5,
    item_pages: [],
    items: [
      {
        id: 1,
      },
      {
        id: 2,
      },
      {
        id: 3,
      },
      {
        id: 4,
      },
      {
        id: 5,
      },
      {
        id: 6,
      },
      {
        id: 7,
      },
      {
        id: 8,
      },
      {
        id: 9,
      },
      {
        id: 10,
      },
    ],
  }),

  created() {
    this.updateItemPages();
  },

  methods: {
    updatePage(page_number) {
      this.current_page = page_number;
      this.updateItemPages();
    },

    updateItemPages() {
      this.item_pages = this.items.slice(
        (this.current_page - 1) * this.per_page,
        (this.current_page - 1) * this.per_page + this.per_page
      );
      if (this.item_pages.length == 0 && this.current_pages == 0) {
        this.updatePage(this.current_pages - 1);
      }
    },
  },

  directives: {
    "b-tooltip": VBTooltip,
  },
};
</script>
<style>
.dark-layout * .shadow {
  box-shadow: 0.5px 0.5px 0.5px 1px #d0d2d6 !important;
}
.scroll-component {
  display: block;
  height: auto;
  max-height: 30rem;
  overflow: scroll;
}
.scroll-component::-webkit-scrollbar {
  width: 10px;
  height: 0px;
}
.scroll-component::-webkit-scrollbar-thumb {
  background-color: #d3eeff;
  border-radius: 10px;
}
.scroll-component::-webkit-scrollbar-track {
  background-color: #ecf5f8;
  border-radius: 10px;
}
.scroll-component::-webkit-scrollbar-corner {
  background-color: #d3eeff;
  border-radius: 10px;
}
.dark-layout * .scroll-component::-webkit-scrollbar-thumb {
  background-color: #343b51 !important;
  border-radius: 10px;
}
.dark-layout * .scroll-component::-webkit-scrollbar-track {
  background-color: #202940 !important;
  border-radius: 10px;
}
.dark-layout * .scroll-component::-webkit-scrollbar-corner {
  background-color: #343b51 !important;
  border-radius: 10px;
}
</style>